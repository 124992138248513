import { subscribeAPI, useAPI } from "@/composables/api";
import { onMounted, onUnmounted, reactive, ComputedRef, computed } from "vue";
import {
  Observable,
  Building,
  ListBuildingsResult,
  BuildingSubscription,
  Building as BuildingType,
} from "@/types/default";
import ListBuildings from "@/graphql/listBuildings.graphql";
import OnCreateBuilding from "@/graphql/subscriptionNewBuilding.graphql";
import OnDeleteBuilding from "@/graphql/subscriptionDeleteBuilding.graphql";
import OnEditBuilding from "@/graphql/subscriptionEditBuilding.graphql";

type Buildings = { [key: string]: Building };
const sortBuilding = (a: BuildingType, b: BuildingType) => {
  return a.name.localeCompare(b.name);
};

export function useBuildings(): {
  buildings: Buildings; sortedBuildings: ComputedRef<Building[]>
} {
  const buildings = reactive<Buildings>({});
  const sortedBuildings = computed(() => Object.values(buildings).sort(sortBuilding));
  let createSubscription: Observable;
  let deleteSubscription: Observable;
  let editSubscription: Observable;

  onMounted(async () => {
    const result = await useAPI<ListBuildingsResult>({
      query: ListBuildings,
    });
    result.data?.listBuildings?.forEach(
      (building) => (buildings[building.id] = building)
    );
    createSubscription = subscribeAPI(
      { query: OnCreateBuilding },
      (response: BuildingSubscription) => {
        const building = response.value.data.onNewBuilding;
        buildings[building.id] = building;
      }
    );
    deleteSubscription = subscribeAPI(
      { query: OnDeleteBuilding },
      (response: BuildingSubscription) => {
        const building = response.value.data.onDeleteBuilding;
        delete buildings[building.id];
      }
    );
    editSubscription = subscribeAPI(
      { query: OnEditBuilding },
      (response: BuildingSubscription) => {
        const building = response.value.data.onEditBuilding;
        buildings[building.id] = building;
      }
    );
  });

  onUnmounted(() => {
    if (createSubscription) createSubscription.unsubscribe();
    if (deleteSubscription) deleteSubscription.unsubscribe();
    if (editSubscription) editSubscription.unsubscribe();
  });

  return { buildings, sortedBuildings };
}
