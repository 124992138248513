import { Ref, ref } from "vue";
import { useAPI } from "@/composables/api";
import { Room, RoomInput } from "@/types/default";
import AddRoom from "@/graphql/addRoom.graphql";
import DeleteRoom from "@/graphql/deleteRoom.graphql";
import EditRoom from "@/graphql/editRoom.graphql";

export function useRoomActions(): {
  error: Ref<object>;
  isRoomLoading: Ref<boolean>;
  addRoom: (item: RoomInput) => void;
  deleteRoom: (id: string) => void;
  editRoom: (id: string, room: RoomInput) => void;
} {
  const isRoomLoading = ref(false);
  const error = ref({
    errorType: 1,
    isError: false
  });
  
  const addRoom = async (item: RoomInput) => {
    isRoomLoading.value = true;
    try {
      await useAPI<Room>({
        query: AddRoom,
        variables: { roomInput: item },
      });
      error.value = {
        errorType: 0,
        isError: false
      };
    } catch (err: any) {
      error.value = {
        errorType: 2,
        isError: true
      };
    }
    isRoomLoading.value = false;
  };
  const deleteRoom = async (id: string) => {
    isRoomLoading.value = true;

    await useAPI<Room>({
      query: DeleteRoom,
      variables: {
        id: id,
      },
    });
    isRoomLoading.value = false;
  };

  const editRoom = async (id: string, room: RoomInput) => {
    isRoomLoading.value = true;
    try {
      await useAPI<Room>({
        query: EditRoom,
        variables: {
          id: id,
          updatedRoom: room,
        },
      });
      error.value = {
        errorType: 0,
        isError: false
      };
    } catch (err: any) {
      error.value = {
        errorType: 2,
        isError: true
      };
    }
    isRoomLoading.value = false;
  };
  return { error, addRoom, isRoomLoading, deleteRoom, editRoom };
}
