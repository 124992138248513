/* eslint-disable */
export const awsconfig = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_fDihrqN91",
    "aws_user_pools_web_client_id": "3eajotagsubdgqbnl8t9mprvb9",
    "aws_appsync_graphqlEndpoint": "https://ckjvfpswrjabbdngpr36nyvtzq.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "federationTarget": "AMAZON_COGNITO_USER_POOLS",
    "oauth": {
        "domain": "prod-mydesk-back.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "openid",
            "profile",
            "aws.cognito.signin.user.admin",
            "email"
        ],
        "redirectSignIn": "https://mydesk.teamwork.net",
        "redirectSignOut": "https://mydesk.teamwork.net",
        "responseType": "code"
    }
};
