import { computed, ComputedRef, onMounted, onUnmounted, reactive } from "vue";
import {
  ListRoomsResult,
  Observable,
  Room as RoomType,
  Room,
  RoomSubscription,
} from "@/types/default";
import { subscribeAPI, useAPI } from "@/composables/api";
import ListRooms from "@/graphql/listRooms.graphql";
import OnCreateRoom from "@/graphql/subscriptionNewRoom.graphql";
import OnDeleteRoom from "@/graphql/subscriptionDeleteRoom.graphql";
import OnEditRoom from "@/graphql/subscriptionEditRoom.graphql";

type Rooms = { [key: string]: Room };

const sortRoom = (a: RoomType, b: RoomType) => {
  const floor = a.floor - b.floor
  const building = a.building.name?.localeCompare(b.building.name);
  const name = a.name.localeCompare(b.name);
  return [building, floor, name].filter((v) => v !== 0).at(0) || 0;

};

export function useRooms(): { rooms: Rooms; sortedRooms: ComputedRef<Room[]> } {
  const rooms = reactive<Rooms>({});
  let createSubscription: Observable;
  let deleteSubscription: Observable;
  let editSubscription: Observable;
  const sortedRooms = computed(() => Object.values(rooms).sort(sortRoom));
  onMounted(async () => {
    const result = await useAPI<ListRoomsResult>({
      query: ListRooms,
    });
    result.data?.listRooms.forEach((room) => (rooms[room.id] = room));

    createSubscription = subscribeAPI(
      { query: OnCreateRoom },
      (response: RoomSubscription) => {
        const room = response.value.data.onNewRoom;
        rooms[room.id] = room;
      }
    );
    deleteSubscription = subscribeAPI(
      { query: OnDeleteRoom },
      (response: RoomSubscription) => {
        const room = response.value.data.onDeleteRoom;

        delete rooms[room.id];
      }
    );
    editSubscription = subscribeAPI(
      { query: OnEditRoom },
      (response: RoomSubscription) => {
        const room = response.value.data.onEditRoom;
        rooms[room.id] = room;
      }
    );
  });
  onUnmounted(() => {
    if (createSubscription) createSubscription.unsubscribe();
    if (deleteSubscription) deleteSubscription.unsubscribe();
    if (editSubscription) editSubscription.unsubscribe();
  });
  return { rooms, sortedRooms };
}
