import { createApp } from "vue";
import App from "./App.vue";
import { Amplify } from "@aws-amplify/core";
import {
  applyPolyfills,
  defineCustomElements,
} from "@aws-amplify/ui-components/loader";
import { awsconfig } from "./aws-exports";
import Pubsub from "@aws-amplify/pubsub";
import router from "@/router/index";
import "bootstrap/dist/css/bootstrap.min.css";
import { createI18n } from "vue-i18n";
import en from "@/lang/en";
import fr from "@/lang/fr";
import { DEFAULT_VALUES } from "@/composables/settings";

applyPolyfills().then(() => {
  defineCustomElements(window);
});
  
type MessageSchema = typeof fr;

const i18n = createI18n<MessageSchema, "fr" | "en">({
  locale: DEFAULT_VALUES.locale,
  legacy: false,
  messages: { en, fr },
  datetimeFormats: {
    fr: {
      short: {
        weekday: "long",
        day: "numeric",
        month: "long",
      },
    },
    en: {
      short: {
        weekday: "long",
        day: "numeric",
        month: "long",
      },
    },
  },
});

Pubsub.configure(awsconfig);
Amplify.configure(awsconfig);

const app = createApp(App);
app
  .use(router)
  .use(i18n)
  .mount("#app");
