import { Ref, ref, computed } from "vue";

export default function usePagination(data: Ref<object[]>, perPage: number) {
  const page = ref(1);
  const nbPages = computed(() => Math.ceil(data.value.length / perPage));

  const paginatedData = computed(() => {
    const results = [];
    const data_tmp = [...data.value];
    while (data_tmp.length) results.push(data_tmp.splice(0, perPage));
    return results;
  });

  const nextPage = () => {
    if (page.value !== Math.ceil(data.value.length / perPage)) {
      page.value += 1;
    }
  };

  const backPage = () => {
    if (page.value !== 1) {
      page.value -= 1;
    }
  };

  const goToPage = (numPage: number) => {
    page.value = numPage;
  };

  return { paginatedData, nbPages, page, nextPage, backPage, goToPage };
}
