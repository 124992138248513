import { createRouter, createWebHistory } from "vue-router";
import Reservation from "@/views/Reservation.vue";
import Preferences from "@/views/Preferences.vue";
import Administration from "@/views/Administration.vue";
import Login from "@/views/Login.vue";
import { Auth } from "@aws-amplify/auth";
import { currentUser, isAdmin } from "@/composables/auth";
import { DEFAULT_VALUES } from "@/composables/settings";

const routes = [
  {
    name: "reservation",
    path: "/reservation/:roomId/:date",
    component: Reservation,
    props: true,
    meta: { requiresLogin: true },
  },
  {
    name: "preferences",
    path: "/preferences",
    component: Preferences,
    meta: { requiresLogin: true },
  },
  {
    name: "administration",
    path: "/admin",
    component: Administration,
    meta: { requiresLogin: true, admin: true },
  },
  {
    name: "home",
    path: "/",
    redirect: {
      name: "reservation",
      params: { roomId: DEFAULT_VALUES.preferredRoom, date: "now" },
    },
  },
  {
    name: "login",
    path: "/login",
    meta: { requiresLogin: false },
    component: Login,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to) => {
  try {
    currentUser.value = await Auth.currentUserPoolUser();
  } catch (e) {
    console.log("not logged");
  }
  if (to.meta.requiresLogin && !currentUser.value) {
    return {
      name: "login",
      query: { redirect: to.fullPath },
    };
  }
  if (to.meta.admin && !isAdmin.value) {
    return {
      name: "home",
      query: { redirect: to.fullPath },
    };
  }
});

export default router;
