import { GraphQLOptions } from "@aws-amplify/api-graphql/src/types/index";
import { API, GraphQLResult } from "@aws-amplify/api";
import { Auth } from "@aws-amplify/auth";
import { Observable } from "@/types/default";

export async function useAPI<T>(
  options: GraphQLOptions
): Promise<GraphQLResult<T>> {
  const session = await Auth.currentSession();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return API.graphql<T>(options, {
    Authorization: session.getIdToken().getJwtToken(),
  });
}

export function subscribeAPI<T>(
  options: GraphQLOptions,
  callback: (response: T) => void
): Observable {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return API.graphql<T>(options).subscribe(callback);
}
