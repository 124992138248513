import { Ref, ref } from "vue";
import { useAPI } from "@/composables/api";
import { Building, BuildingInput } from "@/types/default";
import AddBuilding from "@/graphql/addBuilding.graphql";
import DeleteBuilding from "@/graphql/deleteBuilding.graphql";
import EditBuilding from "@/graphql/editBuilding.graphql";

export function useBuildingActions(): {
  errorBuilding: Ref<object>;
  isBuildingLoading: Ref<boolean>;
  addBuilding: (item: BuildingInput) => void;
  deleteBuilding: (id: string) => void;
  editBuilding: (id: string, building: BuildingInput) => void;
} {
  const isBuildingLoading = ref(false);
  const errorBuilding = ref({
    errorType: 1,
    isError: false
  });

  const addBuilding = async (item: BuildingInput) => {
    isBuildingLoading.value = true;
    try {
      await useAPI<Building>({
        query: AddBuilding,
        variables: { buildingInput: item },
      });
      errorBuilding.value = {
        errorType: 0,
        isError: false
      };
    } catch (err: any) {
      errorBuilding.value = {
        errorType: 2,
        isError: true
      };
    }
    isBuildingLoading.value = false;
  };

  const deleteBuilding = async (id: string) => {
    isBuildingLoading.value = true;

    await useAPI<Building>({
      query: DeleteBuilding,
      variables: {
        id: id,
      },
    });
    isBuildingLoading.value = false;
  };

  const editBuilding = async (id: string, building: BuildingInput) => {
    isBuildingLoading.value = true;
    try {
      await useAPI<Building>({
        query: EditBuilding,
        variables: {
          id: id,
          updatedBuilding: building,
        },
      });
      errorBuilding.value = {
        errorType: 0,
        isError: false
      };
    } catch (err: any) {
      errorBuilding.value = {
        errorType: 2,
        isError: true
      };
    }
    isBuildingLoading.value = false;
  };

  return { errorBuilding, addBuilding, isBuildingLoading, deleteBuilding, editBuilding };
}
