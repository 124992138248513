export default {
  en: "Anglais",
  fr: "Français",
  user: "Utilisateur",
  upcoming_reservations: "Résérvations à venir",
  select_room_label: "Choisissez une salle pour consulter toutes les réservations à venir",
  no_reservation_label: "Aucune réservation prévu dans cette salle",
  cancel_reservation: "Annuler la réservation du ",
  cancel_reservation_confirm: "Annuler la réservation",
  cancel_reservation_label: "Êtes-vous sûr ?",
  back: "Retour",
  reservations_link: "Réservations",
  reserved: "Réservé",
  preferences_link: "Préférences",
  administration_link: "Administration",
  room_management_label:"Gestion des salles",
  building_management_label:"Gestion des bâtiments",
  sign_out: "Déconnexion",
  date_label: "Date",
  room_label: "Salle",
  room_select_placeholder: "Sélectionner une salle",
  floor_label: "Étages",
  floors_label: "RDC | Niveau 1 | Niveau {count} ",
  add_floors_label: "RDC | 1 | {count}",
  floor_count_label: "étage | étages",
  seats_label: "place | places",
  room_loading: "Chargement de la salle...",
  reserve: "Réserver",
  cancel: "Annuler",
  available: "Libre | Libres",
  unavailable: "Non disponible",
  avatar_label: "Style des avatars",
  delete_floor: "Supprimer l'étage",
  add_floor: "Ajouter l'étage",
  add_building: "Ajouter le bâtiment",
  edit_building: "Éditer le bâtiment",
  delete_building: "Supprimer le bâtiment",
  building_name_label: "Nom du bâtiment",
  building_name_placeholder: "Entrez un nom pour le bâtiment",
  building_label: "Bâtiment",
  building_floors_label: "Ajouter un étage",
  building_floors_placeholder:
    "Entrez le numéro d'étage",
  building_placeholder: "Sélectionner un bâtiment",
  floor_placeholder: "Sélectionner un étage",
  building_delete_modal: "Êtes-vous sûr de vouloir supprimer ce bâtiment et toutes les salles associées ?",
  room_delete_modal: "Êtes-vous sûr de vouloir supprimer cette salle ?",
  room_name_label: "Nom de la salle",
  room_name_placeholder: "Entrer le nom de la salle",
  seat_count_label: "Nombre de places",
  seat_count_placeholder: "Entrer le nombre de place",
  add_ground_floor: "Ajouter le RDC",
  add_room: "Ajouter la salle",
  delete_room: "Supprimer la salle",
  edit_room: "Éditer la salle",
  lang_label: "Langue",
  klaxit_label: "Chauffeur Klaxit",
  toast_error_floor: "Veuillez entrer un étage supérieur à 0 s'il vous plait",
  toast_error_seat: "Le nombre de places doit être supérieur à 0",
  invalid_feedback_building:
    "Veuillez sélectionner un bâtiment s'il vous plait",
  invalid_feedback_name: "Entrez un nom pour la salle | Le nom indiqué est déjà utilisé",
  invalid_feedback_floor: "Ajoutez au moins un étage au bâtiment",
  invalid_feedback_add_floor: "L'étage indiqué est invalide | L'étage indiqué existe déjà",
  invalid_feedback_building_name: "Entrez un nom pour le bâtiment | Le nom indiqué est déjà utilisé",
  preferred_room_label: "Salle préférée",
  floor_levels: "Étage | Étages",
  layout_label: "Disposition",
  fixed: "Fixe",
  fluid: "Fluide",
  days: {
    monday: "Lundi",
    tuesday: "Mardi",
    wednesday: "Mercredi",
    thursday: "Jeudi",
    friday: "Vendredi",
    saturday: "Samedi",
    sunday: "Dimanche",
  },
  percentage: "Capacité",
  day: "Jour",
  save: "Enregistrer",
  override_confirmation:
    "Cela annulera votre réservation du {date} dans la salle '{room}'",
  reports_pane_title: "Rapports de réservations hebdomadaires",
  reservation_pane_title: "Réservation",
  download: "Télécharger",
  generated_on: "Généré le",
  period: "Période",
  from_date: "Du",
  to_date: "au",
  no_data: "Aucune donnée",
  create_building: "Ajouter un bâtiment",
  create_room: "Ajouter une salle",
  existing_rooms: "Salles existantes",
  existing_buildings: "Bâtiments existants",
  floors: "Étage | Étage | Étages",
  error: "Erreur",
};
