import { Ref, ref } from "vue";
import { useAPI } from "@/composables/api";
import Reserve from "@/graphql/reserve.graphql";
import Cancel from "@/graphql/cancel.graphql";
import GetReservation from "@/graphql/getReservation.graphql";
import { GetReservationResult, Reservation } from "@/types/default";

export function useBooking(
  date: Ref<string>,
  roomId: Ref<string>
): {
  book: (isAdmin: boolean) => void;
  cancel: () => void;
  isLoading: Ref<boolean>;
  checkOtherReservation: () => Promise<Reservation | undefined>;
} {
  const isLoading = ref(false);
  
  const book = async (isAdmin: boolean) => {
    isLoading.value = true;
    await useAPI<Reservation>({
      query: Reserve,
      variables: {
        date: date.value,
        roomId: roomId.value,
        adminReservation: isAdmin,
      },
    });
    isLoading.value = false;
  };

  const cancel = async () => {
    isLoading.value = true;
    await useAPI<Reservation>({
      query: Cancel,
      variables: {
        date: date.value,
      },
    });
    isLoading.value = false;
  };

  const checkOtherReservation = async () => {
    isLoading.value = true;
    const response = await useAPI<GetReservationResult>({
      query: GetReservation,
      variables: { date: date.value },
    });
    isLoading.value = false;

    return response.data?.getReservation;
  };

  return { book, isLoading, cancel, checkOtherReservation };
}
