export default {
  en: "English",
  fr: "French",
  user: "User",
  upcoming_reservations: "Upcoming Reservations",
  select_room_label: "Please select a room to see the upcoming reservations",
  no_reservation_label: "There is no reservation upcoming in this room",
  cancel_reservation: "Cancel reservation for ",
  cancel_reservation_confirm: "Cancel reservation",
  cancel_reservation_label: "Are you sure?",
  back: "Go back",
  reservations_link: "Booking",
  reserved: "Booked",
  preferences_link: "Settings",
  administration_link: "Administration",
  room_management_label:"Room management",
  building_management_label:"Building management",
  sign_out: "Sign out",
  date_label: "Date",
  room_label: "Room",
  room_select_placeholder: "Select a room",
  floor_label: "Floors",
  floors_label: "Ground floor | Level 1 | Level {count}",
  add_floors_label: "Ground floor | 1 | {count}",
  floor_count_label: "floor | floors",
  floor_levels: "Floor level | Floor levels",
  seats_label: "seat | seats",
  room_loading: "Room loading ...",
  reserve: "Book",
  cancel: "Cancel",
  delete_floor: "Delete floor",
  available: "Available",
  unavailable: "Unavailable",
  avatar_label: "Avatar style",
  add_floor: "Add floor",
  add_building: "Add building",
  edit_building: "Edit building",
  delete_building: "Delete building",
  building_name_label: "Building name",
  building_name_placeholder: "Enter the building name",
  building_label: "Building",
  building_floors_label: "Add floor level",
  building_floors_placeholder: "Enter floor level",
  building_placeholder: "Select a building",
  floor_placeholder: "Select a floor",
  building_delete_modal: "Are you sure you want to delete this building and all the rooms associated?",
  room_delete_modal: "Are you sure you want to delete this room?",
  room_name_label: "Room name",
  room_name_placeholder: "Enter the name of the room",
  seat_count_label: "Seat count",
  seat_count_placeholder: "Enter the seat count for the room",
  add_ground_floor: "Add ground floor",
  add_room: "Add room",
  delete_room: "Delete room",
  edit_room: "Edit room",
  lang_label: "Language",
  klaxit_label: "Klaxit Driver",
  toast_error_floor: "Please add a floor superior to 0",
  toast_error_seat: "The number of seat count must be superior to 0",
  invalid_feedback_building: "Please select the building",
  invalid_feedback_floor: "Please enter a floor level",
  invalid_feedback_add_floor: "The indicated floor is invalid | The indicated floor already exists",
  invalid_feedback_name: "Please enter the name of the room | A room with this name already exists",
  invalid_feedback_building_name: "Please enter a name for the building | A building with this name already exists",
  preferred_room_label: "Favorite room",
  layout_label: "Layout",
  fixed: "Fixed",
  fluid: "Fluid",
  days: {
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
  },
  percentage: "Capacity",
  day: "Day",
  save: "Save",
  override_confirmation:
    "This will override your current booking in room '{room}' on {date}",
  reports_pane_title: "Weekly bookings reports",
  reservation_pane_title: "Reservation",
  download: "Download",
  generated_on: "Generated on",
  period: "Period",
  from_date: "From",
  to_date: "to",
  no_data: "No data",
  create_building: "Create a building",
  create_room: "Create a room",
  existing_rooms: "Existing rooms",
  existing_buildings: "Existing buildings",
  floors: "Floors | Floor | Floors",
  error: "Error",
};
