import { onMounted, reactive, ref, Ref, watch, computed, ComputedRef } from "vue";
import { subscribeAPI, useAPI } from "@/composables/api";
import ListAdminReservations from "@/graphql/listAdminReservation.graphql";
import SubscribeAdminReservation from "@/graphql/subscriptionAdminReservation.graphql";
import SubscribeCancelAdmin from "@/graphql/subscriptionAdminCancel.graphql";
import {
  ListAdminReservationResult,
  ReservationSubscription,
  Reservation,
  Reservation as ReservationType,
  Observable,
} from "@/types/default";

type Reservations = { [key: string]: Reservation };

const sortReservations = (a: ReservationType, b: ReservationType) => {
  return a.date?.localeCompare(b.date);
};
export function useAdminReservationList(
  roomId: Ref<string>
): { isLoading: Ref<boolean>; adminReservations: Reservations; sortedAdminReservations: ComputedRef<Reservation[]> } {
  let reserveSubscription: Observable, cancelSubscription: Observable;
  const adminReservations = reactive<Reservations>({});
  const sortedAdminReservations = computed(() => Object.values(adminReservations).sort(sortReservations));
  const isLoading = ref(false);

  const loadData = async () => {
    isLoading.value = true;
    if (roomId.value === undefined) return;
    // Cleanup from previous executions
    for (const key of Object.keys(adminReservations)) {
      delete adminReservations[key];
    }
    if (reserveSubscription) reserveSubscription.unsubscribe();
    
    const result = await useAPI<ListAdminReservationResult>({
      query: ListAdminReservations,
      variables: {
        roomId: roomId.value,
      },
    });
    
    result.data?.listAdminReservations.forEach((item: Reservation) => {
      adminReservations[item.date] = item;
    });

    reserveSubscription = subscribeAPI(
      {
        query: SubscribeAdminReservation,
        variables: {
          roomId: roomId.value,
        },
      },
      (response: ReservationSubscription) => {
        const event = response.value.data.onNewAdminReservation;
        adminReservations[event.date] = event;
      }
    )
    cancelSubscription = subscribeAPI(
      {
        query: SubscribeCancelAdmin,
        variables: {
          roomId: roomId.value,
        },
      },
      (response: ReservationSubscription) => {
        const event = response.value.data.onCancelAdminReservation;
        delete adminReservations[event.date];
      }
    );
    isLoading.value = false;
  };
  onMounted(async () => await loadData());
  watch(roomId, async () => await loadData());

  return { adminReservations, sortedAdminReservations, isLoading };
}
