import { computed, ref } from "vue";

export const currentUser = ref();
export const userId = computed(() => currentUser.value?.username);
export const isAdmin = computed(
  () =>
    currentUser.value?.signInUserSession.idToken.payload[
      "cognito:groups"
    ].includes("admin") ||
    currentUser.value?.signInUserSession.idToken.payload[
      "cognito:groups"
    ].includes("super_admin")
);
export const isSuperAdmin = computed(() =>
  currentUser.value?.signInUserSession.idToken.payload[
    "cognito:groups"
  ].includes("super_admin")
);
