import { useAPI } from "@/composables/api";
import { onMounted, ref, Ref } from "vue";
import { Report, ListReportsResult, GetReportUrlResult } from "@/types/default";
import ListReports from "@/graphql/listReports.graphql";
import GetReportUrl from "@/graphql/getReportUrl.graphql";

type ApiError = { errors: Error[] };

export default function useReports(): {
  reports: Ref<Report[]>;
  getReportUrl: (reportName: string) => Promise<string | undefined>;
} {
  const reports = ref<Report[]>([]);

  onMounted(async () => {
    let result;
    try {
      result = await useAPI<ListReportsResult>({
        query: ListReports,
      });
    } catch (error) {
      for (const e of (error as ApiError).errors) {
        console.warn(e.message);
      }
    }
    reports.value = result?.data?.listReports || [];
  });

  const getReportUrl = async (reportName: string) => {
    const response = await useAPI<GetReportUrlResult>({
      query: GetReportUrl,
      variables: { reportName },
    });
    return response.data?.getReportUrl;
  };

  return { reports, getReportUrl };
}
