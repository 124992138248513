import { onMounted, reactive, ref, Ref, watch } from "vue";
import { subscribeAPI, useAPI } from "@/composables/api";
import ListReservations from "@/graphql/listReservation.graphql";
import SubscribeReservation from "@/graphql/subscriptionReservation.graphql";
import SubscribeCancel from "@/graphql/subscriptionCancel.graphql";
import {
  ListReservationResult,
  Observable,
  Reservation,
  ReservationSubscription,
  Room,
} from "@/types/default";

type Reservations = { [key: string]: Reservation };

export function useReservationList(
  date: Ref<string>,
  room: Ref<Room>,
): { isLoading: Ref<boolean>; reservations: Reservations } {
  const reservations = reactive<Reservations>({});
  const isLoading = ref(false);
  let reserveSubscription: Observable, cancelSubscription: Observable;

  const loadData = async () => {
    isLoading.value = true;
    if (date.value === undefined || room.value === undefined) return;

    // Cleanup from previous executions
    for (const key of Object.keys(reservations)) {
      delete reservations[key];
    }
    if (reserveSubscription) reserveSubscription.unsubscribe();
    if (cancelSubscription) cancelSubscription.unsubscribe();

    const result = await useAPI<ListReservationResult>({
      query: ListReservations,
      variables: { date: date.value, roomId: room.value?.id },
    });
    result.data?.listReservations.forEach((item: Reservation) => {
      reservations[item.userId] = item;
    });
    reserveSubscription = subscribeAPI(
      {
        query: SubscribeReservation,
        variables: {
          date: date.value,
          roomId: room.value.id,
        },
      },
      (response: ReservationSubscription) => {
        const event = response.value.data.onNewReservation;
        reservations[event.userId] = event;
      }
    );
    cancelSubscription = subscribeAPI(
      {
        query: SubscribeCancel,
        variables: {
          date: date.value,
          roomId: room.value.id,
        },
      },
      (response: ReservationSubscription) => {
        const event = response.value.data.onCancelReservation;
        delete reservations[event.userId];
      }
    );
    isLoading.value = false;
  };
  onMounted(async () => await loadData());
  watch(date, async () => await loadData());
  watch(room, async () => await loadData());

  return { reservations, isLoading };
}
